<template>
  <b-row>
    <b-col md="12">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-card>
            <!-- <b-card-header><b class="h1">Basic information</b></b-card-header> -->
            <b-card-body>
              <b-row>
                <b-col md="12">
                  <b-form-group label="Display on/off:" label-cols-md="3">
                    <b-form-checkbox
                      v-model="model.is_active"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Warehouse:" label-cols-md="3">
                    <b-form-checkbox
                      v-model="model.is_warehouse"
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <BasicInformation
            id="BI"
            v-model="model"
            :categoryIdErrorText="categoryIdErrorText"
          />
          <SalesInformation id="SI" v-model="model" />
          <MediaManagement id="MM" v-model="model" />
          <!-- <ShippingDetail id="SD" v-model="model" /> -->
          <SEOInfo id="SE" v-model="model" />
          <!-- submit button -->
          <b-card>
            <b-card-body class="text-right">
              <div class="w-75 ml-auto" style="font-size: 12px;">
                <p class="text-muted">
                  If your item sells, we charge a final value fee based on a
                  percentage of the total cost to the buyer, less any sales tax.
                </p>
                <p class="text-muted">
                  By clicking the List item button, you agree to pay fees above,
                  accept the listing conditions above, and assume full
                  responsibility for the content of the listing and item
                  offered.
                </p>
              </div>
              <div class="d-flex align-items-center justify-content-end mt-2">
                <b-button variant="outline-secondary" type="button">
                  Save and Delist
                </b-button>
                <b-button
                  variant="primary"
                  type="submit"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </validation-observer>
    </b-col>
    <!-- <b-col sm="12" md="3" class="position-relative h-100">
      <div class="position-fixed right-fixed-menu">
        <b-card class="m-0">
          <b-card-body>
            <scrollactive
              active-class="active"
              :offset="150"
              :duration="400"
              bezier-easing-value=".5,0,.35,1"
            >
              <b-link href="#BI" class="scrollactive-item d-block">
                Basic information
              </b-link>
              <b-link href="#SI" class="scrollactive-item d-block">
                Sales information
              </b-link>
              <b-link href="#MM" class="scrollactive-item d-block">
                Media management
              </b-link>
              <b-link href="#SD" class="scrollactive-item d-block">
                Shipping detail
              </b-link>
              <b-link href="#SE" class="scrollactive-item d-block">
                SEO Optimization
              </b-link>
            </scrollactive>
          </b-card-body>
        </b-card>
      </div>
    </b-col> -->
  </b-row>
</template>
<script>
/* eslint-disable array-callback-return */
import VueScrollactive from 'vue-scrollactive'
import {
  BRow,
  BCol,
  // BLink,
  BForm,
  BButton,
  BCard,
  BCardBody,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import BasicInformation from '@/views/pages/product/product-add/BasicInformation.vue'
import SalesInformation from '@/views/pages/product/product-add/SalesInformation.vue'
import MediaManagement from '@/views/pages/product/product-add/MediaManagement.vue'
// import ShippingDetail from '@/views/pages/product/product-add/ShippingDetail.vue'
import SEOInfo from '@/views/pages/product/product-add/SEOInfo.vue'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.use(VueScrollactive)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    // BLink,
    BasicInformation,
    SalesInformation,
    MediaManagement,
    // ShippingDetail,
    SEOInfo,
    BButton,
    ValidationObserver,
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
  },
  mixins: [general],
  data() {
    return {
      categoryIdErrorText: null,
      model: {
        // Common
        is_active: true,
        sku: null,
        position: 0,
        is_warehouse: false,
        // Basic Information
        name: null,
        short_content: [
          {
            type: 'table',
            value: [
              {
                title: null,
                content: null,
              },
            ],
          },
          {
            type: 'content',
            value: null,
          },
        ],
        category_id: null,
        category_name: null,
        brand_id: null,
        origin_id: null,
        supplier_id: null,
        content: null,
        // Sale Information
        price: null,
        variationTable: {
          colName: [
            {
              key: 'variation_1',
              value: '',
            },
            {
              key: 'variation_2',
              value: '',
            },
            {
              key: 'price',
              value: 'Price',
            },
            {
              key: 'quantity',
              value: 'Quantity',
            },
            {
              key: 'sku',
              value: 'SKU',
            },
            {
              key: 'active',
              value: 'Active',
            },
          ],
          colVal: {
            variation_1: [''],
            variation_2: [''],
          },
          wareHouse: [],
        },
        ware_house: [],
        // Media
        avatar: null,
        list_thumbnail: [],
        // Shipping detail
        // weight: null,
        // length: null,
        // height: null,
        // SEO Info
        title_page: null,
        meta_keyword: [],
        meta_description: null,
        slug: null,
      },
      // model: {
      //   // Common
      //   is_active: true,
      //   sku: 'IPADMINI4GPROMAX',
      //   // position: null,
      //   is_warehouse: false,
      //   // Basic Information
      //   name: 'IPAD MINI 4G PROMAX',
      //   short_content: [
      //     {
      //       type: 'table',
      //       value: [
      //         {
      //           title: 'Màn hình',
      //           content: 'OLED, 6.1", Super Retina XDR',
      //         },
      //         {
      //           title: 'Hệ điều hành',
      //           content: 'iOS 14',
      //         },
      //         {
      //           title: 'Camera sau',
      //           content: 'Trước: 12 MP, Sau: 2 camera 12 MP',
      //         },
      //         {
      //           title: 'Thương hiệu',
      //           content: 'AppleDog',
      //         },
      //         {
      //           title: 'CPU',
      //           content: 'Apple A14 Bionic 20 nhân',
      //         },
      //       ],
      //     },
      //     {
      //       type: 'content',
      //       value:
      //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //     },
      //   ],
      //   category_id: null,
      //   category_name: null,
      //   brand_id: null,
      //   origin_id: null,
      //   supplier_id: null,
      //   content: '<h2>Why do we use it?</h2>',
      //   // Sale Information
      //   price: 32000000,
      //   // variationTable: {
      //   //   colName: [
      //   //     {
      //   //       key: 'variation_1',
      //   //       value: '',
      //   //     },
      //   //     {
      //   //       key: 'variation_2',
      //   //       value: '',
      //   //     },
      //   //     {
      //   //       key: 'price',
      //   //       value: 'Price',
      //   //     },
      //   //     {
      //   //       key: 'quantity',
      //   //       value: 'Quantity',
      //   //     },
      //   //     {
      //   //       key: 'sku',
      //   //       value: 'SKU',
      //   //     },
      //   //     {
      //   //       key: 'active',
      //   //       value: 'Active',
      //   //     },
      //   //   ],
      //   //   colVal: {
      //   //     variation_1: [''],
      //   //     variation_2: [''],
      //   //     variation_3: [''],
      //   //   },
      //   //   wareHouse: [],
      //   // },
      //   ware_house: [],
      //   // Media
      //   avatar: null,
      //   list_thumbnail: [],
      //   // Shipping detail
      //   // weight: null,
      //   // length: null,
      //   // height: null,
      //   // SEO Info
      //   title_page: 'Page bán hàng',
      //   meta_keyword: [
      //     'RED-256GB',
      //     'GOLD-256GB',
      //     'SILVER-128GB',
      //     'SILVER-256GB',
      //     'GOLD-128GB',
      //     'RED-128GB',
      //   ],
      //   meta_description:
      //     "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      //   slug: 'ipad-mini-4g',
      // },
    }
  },
  methods: {
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (this.model.category_id === null) {
          this.categoryIdErrorText = 'The Category field is required'
        }
        if (success) {
          try {
            const params = {
              sku: this.model.sku,
              template_id: JSON.parse(localStorage.getItem('templateID')).id,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              position: Number(this.model.position),
              short_content: JSON.stringify(this.model.short_content),
              platform_category_id: this.model.category_id,
              price: this.model.price,
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
              slug: this.model.slug,
              is_warehouse: Number(this.model.is_warehouse === true ? 1 : 0),
            }
            // if (this.model.ware_house && this.model.ware_house.length > 0) {
            //   params.warehouse = []
            //   this.model.ware_house.map(item => {
            //     params.warehouse.push(JSON.stringify(item))
            //   })
            // }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            if (this.model.brand_id !== null) {
              params.trademark_id = this.model.brand_id
            }
            if (this.model.origin_id !== null) {
              params.origin_id = this.model.origin_id
            }
            if (this.model.supplier_id !== null) {
              params.supplier_id = this.model.supplier_id
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail.map(async x => {
                const thumbnail = {
                  template_id: JSON.parse(localStorage.getItem('templateID'))
                    .id,
                  table: 'platform_gallery',
                  table_media: 'platform_medias',
                  type: 'desktop',
                  avatar: x,
                  table_field: 'list_thumbnail',
                }
                const resIMG = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_IMG_URL}/create_only_platform`,
                  thumbnail,
                )
                if (resIMG.status === 200) {
                  if (listThumbnailId) {
                    listThumbnailId += `,${resIMG.data.data.id}`
                  } else {
                    listThumbnailId = resIMG.data.data.id
                  }
                  listThumbnail.push(resIMG.data.data.path)
                }
                index += 1

                if (index === this.model.list_thumbnail.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handleCreate(params)
                }
              })
            } else {
              this.handleCreate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async handleCreate(params) {
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/platform_product`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Product success',
            },
          })
          this.$router.push('/product/list')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Login fail',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
</style>
