<template>
  <b-card>
    <b-card-header><b class="h1">SEO Optimization</b></b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="12">
          <b-form-group label="Title page:" label-cols-md="3">
            <b-form-input v-model="value.title_page" placeholder="Title page" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Slug:" label-cols-md="3">
            <b-form-input v-model="value.slug" placeholder="Slug" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Meta keyword:" label-cols-md="3">
            <b-form-tags
              v-model="value.meta_keyword"
              input-id="tags-remove-on-delete"
              :input-attrs="{
                'aria-describedby': 'tags-remove-on-delete-help',
              }"
              separator=" "
              placeholder="Enter new keyword separated by space"
              remove-on-delete
              no-add-on-enter
              class="mb-2"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Meta description:" label-cols-md="3">
            <b-form-textarea
              v-model="value.meta_description"
              rows="6"
              max-rows="6"
              no-resize
              placeholder="Meta description"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BFormTags,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTags,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
